/* eslint-disable jsx-a11y/anchor-is-valid */
import { FaPrint } from "react-icons/fa";
// import { clone } from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import React, { memo, useContext, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Pdf from "react-to-pdf";
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// import htmlToPdfmake from "html-to-pdfmake";

// import download from "downloadjs";
// import firebase from "gatsby-plugin-firebase";
import { useSelector } from "contexts/resume/resume.provider";
import BaseModal from "../BaseModal";
import Button from "components/shared/Button";
import ModalContext from "contexts/modal/modal.provider";
// import { b64toBlob } from "utils";
import SettingsContext from "contexts/settings/settings.provider";

const ExportModal = () => {
  const state = useSelector();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  // const [isLoadingSingle, setLoadingSingle] = useState(false);
  // const [isLoadingMulti, setLoadingMulti] = useState(false);
  const { printRef } = React.useContext(SettingsContext);

  const { emitter, events } = useContext(ModalContext);

  useEffect(() => {
    const unbind = emitter.on(events.EXPORT_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const handleOpenPrintDialog = useReactToPrint({
    content: () => printRef.current,
  });

  // const handleDownload = () => {
  //   console.log("================", printRef);
  //   console.log("================", printRef.current);
  //   console.log("================", `${printRef.current}`);
  //   const resumeElement = printRef.current;
  //   pdfMake.vfs = pdfFonts.pdfMake.vfs;
  //   var filename = `${state.name}-${state.id}`;

  //   var html = htmlToPdfmake(`
  //     <div>
  //       <h1>The Database Resume</h1>
  //       <p>
  //         coming soon!
  //       </p>
  //     </div>
  //   `);

  //   var val = htmlToPdfmake(`${resumeElement}`);
  //   var dd = { content: val };
  //   var ee = { content: html };
  //   console.log(val);
  //   pdfMake.createPdf(ee).download();
  //   pdfMake.createPdf(ee).download();
  // };

  const handleExportToJson = () => {
    // const backupObj = clone(state);
    // delete backupObj.id;
    // delete backupObj.user;
    // delete backupObj.name;
    // delete backupObj.createdAt;
    // delete backupObj.updatedAt;
    // const data = `data:text/json;charset=utf-8,${encodeURIComponent(
    //   JSON.stringify(backupObj, null, "\t"),
    // )}`;
    // download(data, `RxResume-${state.id}.json`, "text/json");

    toast("Feature Coming Soon");
  };

  return (
    <BaseModal
      hideActions
      state={[open, setOpen]}
      title={t("builder.actions.export.heading")}
    >
      <div>
        <h5 className="text-xl font-semibold mb-4">
          {t("modals.export.printDialog.heading")}
        </h5>

        <p className="leading-loose">{t("modals.export.printDialog.text")}</p>

        <Button icon={FaPrint} className="mt-5" onClick={handleOpenPrintDialog}>
          {t("modals.export.printDialog.button")}
        </Button>
      </div>

      <hr className="my-8" />

      <div>
        <h5 className="text-xl font-semibold mb-4">
          {t("modals.export.downloadPDF.heading")}
        </h5>

        <p className="leading-loose">{t("modals.export.downloadPDF.text")}</p>

        <div className="mt-5 mb-4">
          <div className="flex">
            <Pdf
              targetRef={printRef}
              filename={`${state.name}-${state.id}.pdf`}
              x={0.5}
              y={0.5}
              scale={0.8}
            >
              {({ toPdf }) => (
                <Button onClick={toPdf}>
                  {t("modals.export.downloadPDF.buttons.single")}
                </Button>
              )}
            </Pdf>
            {/* <Button onClick={() => handleDownload()}>
              {t("modals.export.downloadPDF.buttons.single")}
            </Button> */}
          </div>
        </div>
      </div>

      <hr className="my-8" />

      <div>
        <h5 className="text-xl font-semibold mb-4">
          {t("modals.export.jsonFormat.heading")}
        </h5>

        <p className="leading-loose">{t("modals.export.jsonFormat.text")}</p>

        <div className="mt-5">
          <Button onClick={handleExportToJson}>
            {t("modals.export.jsonFormat.button")}
          </Button>
          <a id="downloadAnchor" className="hidden">
            {t("modals.export.jsonFormat.button")}
          </a>
        </div>
      </div>
    </BaseModal>
  );
};

export default memo(ExportModal);
