import styled from "styled-components";

export const LayoutWrapper = styled.div`
  .reuseModalHolder {
    padding: 0;
    overflow: auto;
    border-radius: 3px 3px 0 0;
    border: 0;
  }
`;
