const themeConfig = {
  Light: {
    "--color-primary-50": "#FFFFFF",
    "--color-primary-100": "#FAFAFA",
    "--color-primary-200": "#F1F0F0",
    "--color-primary-300": "#D8D2CD",
    "--color-primary-400": "#CDC4BA",
    "--color-primary-500": "#ABA59D",
    "--color-primary-600": "#8A8680",
    "--color-primary-700": "#686663",
    "--color-primary-800": "#484745",
    "--color-primary-900": "#1849B1",
  },
  Dark: {
    "--color-primary-50": "#212121",
    "--color-primary-100": "#2c2c2c",
    "--color-primary-200": "#424242",
    "--color-primary-300": "#616161",
    "--color-primary-400": "#757575",
    "--color-primary-500": "#9e9e9e",
    "--color-primary-600": "#bdbdbd",
    "--color-primary-700": "#e0e0e0",
    "--color-primary-800": "#eeeeee",
    "--color-primary-900": "#f5f5f5",
  },
  AMOLED: {
    "--color-primary-50": "#010101",
    "--color-primary-100": "#121212",
    "--color-primary-200": "#222222",
    "--color-primary-300": "#333333",
    "--color-primary-400": "#444",
    "--color-primary-500": "#696969",
    "--color-primary-600": "#8F8F8F",
    "--color-primary-700": "#B4B4B4",
    "--color-primary-800": "#DADADA",
    "--color-primary-900": "#FFFFFF",
  },
};

export default themeConfig;
